@import '~rsuite/dist/styles/rsuite-dark.css';

.App {
    font-family: sans-serif;
    text-align: center;
}

html {
    user-select: none;
}

body {
    font-family: -apple-system, BlinkMacSystemFont, 'Segoe UI', Roboto, Helvetica,
    Arial, sans-serif, 'Apple Color Emoji', 'Segoe UI Emoji', 'Segoe UI Symbol';
    margin: 0;
    padding: 0;
    background-color: #282C34;
}

div, video {
    outline-style: none;
}

@font-face {
    font-family: "Data 70";
    src: url("../fonts/b7d46e03e12786ea1f23babc4606ad90.eot"); /* IE9*/
    src: url("../fonts/b7d46e03e12786ea1f23babc4606ad90.eot?#iefix") format("embedded-opentype"), /* IE6-IE8 */ url("../fonts/b7d46e03e12786ea1f23babc4606ad90.woff2") format("woff2"), /* chrome firefox */ url("../fonts/b7d46e03e12786ea1f23babc4606ad90.woff") format("woff"), /* chrome firefox */ url("../fonts/b7d46e03e12786ea1f23babc4606ad90.ttf") format("truetype"), /* chrome firefox opera Safari, Android, iOS 4.2+*/ url("../fonts/b7d46e03e12786ea1f23babc4606ad90.svg#Data 70") format("svg"); /* iOS 4.1- */
}

.visualsource-logo {
    font-family: "Data 70", "arial", "serif";
    font-size: 40px;
    padding: 20px;
}

a.visualsource-logo {
    text-decoration: none;
}

a.visualsource-logo:hover {
    text-decoration: none;
}

div.videoThumbBox {
    background-color: #222;
    border-radius: 8px;
    padding: 0;
    margin: 8px;
    border-width: 1px;
    border-color: #282C34;
    border-style: solid;
}

div.videoThumbBox:hover {
    border-color: #ccc;
    border-width: 1px;
    border-style: solid;
}

div.videoThumbFooter {
    padding: 8px 16px;
    color: #888;
    font-weight: bold;
    overflow: hidden;
    white-space: nowrap;
    text-overflow: ellipsis;
    text-align: left;
}

.videoThumbLink:hover {
    text-decoration: none;
    border-color: #fff;
}

div.thumbFrame {
    padding-top: 56.25%;
    position: relative;
    height: 0;
}

span.lazy-load-image-loaded {
    position: absolute;
    top: 0;
    width: 100%;
}

img.thumbImage {
    border-radius: 0;
}

div.mainContent {
    min-height: calc(100vh - 100px);
}

div.rs-sidenav-body {
    min-height: calc(100vh - 100px);
    background: -webkit-gradient(linear, left top, left bottom, color-stop(0%,rgba(255,255,255,0)), color-stop(100%,rgba(40,44,52,1)));
}

div.sideNav a:hover, a:link, a:visited {
    text-decoration: none;
}

div.rs-footer {
    text-align: center;
    height: 40px;
    color: #555;
}

div.rs-footer a, a.visited, a.link {
    color: #aaa;
}

div.rs-footer a:hover {
    color: #fff;
}

.navbar-fixed {
    position: fixed;
    top: 0;
    width: 100%;
    overflow: hidden;
    z-index: 1;
}

.mainContent {
    margin-top: 56px;
}

.mainFixed {
    max-width: 1400px;
    margin: 56px auto auto;
}

.navBarFixed {
    margin: auto;
}

div.player {
    max-width: 1400px;
    width: auto;
    height: auto;
    background-color: #222;
    border-radius: 8px;
    padding: 0;
    border-width: 1px;
    border-color: #282C34;
    border-style: solid;
    user-select: none;
    margin: 8px auto 20px;
}

.videoPlayer {
    border-radius: 16px;
    padding-top: 56.25%;
    position: relative;
}

.videoPlayer > div {
    position: absolute;
    top: 0;
}

.videoPlayer > video {
    position: absolute;
    left: 0;
    top: 0;
}


div.videoThumbFooterBig {
    padding: 16px 20px 10px 20px;
    margin-bottom: 6px;
    color: #888;
    font-weight: bold;
    overflow: hidden;
    white-space: nowrap;
    text-overflow: ellipsis;
    text-align: left;
    font-size: 18px;
    direction: ltr;
}

div.videoRepoText {
    direction: rtl;
}

div.videoThumbFooterBig a {
    color: #666;
}

div.videoThumbFooterBig a:hover {
    color: #888;
    text-decoration: none;
}

div.discussContainer {
    margin: 9px;
}

div.renderingVideo {
    margin: 20px;
}

div.loaderClass {
    margin-bottom: 50px;
    padding: 30px;
}

div.loaderClassPercent {
    padding: 30px;
}


div.createVideo {
    margin: 20px;
}


div.videoPosted {
    font-weight: normal;
    font-size: 12px;
}

a.loginButton:hover {
    text-decoration: none;
    color: #fff;
}

a.loginButton:link, a.loginButton:visited {
    text-decoration: none;
}

div.loginError {
    margin: 40px;
}

@media only screen and (max-width: 475px) {
    .desktopMenu {
        display: none;
    }
}

@media only screen and (min-width: 475px) {
    .mobileMenu {
        display: none;
    }
}

@media only screen and (max-width: 350px) {
    .mobileMenu {
        display: none;
    }
}

.repoList {
    border-radius: 8px;
}

div.planItemName {
    width: 140px;
    float: left;
}

.repoHeader {
    margin: 0 20px;
    width: 500px;
    background-color: #1A1D24;
    padding: 20px;
    font-weight: bold;
    border-radius: 8px;
    text-align: center;
}

.repoMissingText {
    margin-top: -10px;
    color: #888;
    font-size: 12px;
    text-align: center;
}

div.staticPages {
    padding: 20px;
}

div.staticPages div.rs-panel-body {
    color: #A4A9B3;
}

div.staticPages h3 {
    margin-top: 30px;
    color: #c4c9d3;
}

div.staticPages a {
    color: #7b7d85;
}

.repoViewButton {
    float: left;
    font-weight: bold;
    height: 50px;
    margin-top: -7px;
    padding: 0 20px;
    font-size: 16px;
}

.repoViewMetaData {
    float: left;
    padding-left: 20px;
    padding-top: 0;
    margin-top: -7px;
    font-weight: normal;
    font-size: 14px;
}

.printToMousePad {
    float: right;
}

@media only screen and (max-width: 800px) {
    .repoViewMetaData {
        display: none;
    }
    .repoViewButton {
        font-weight: normal;
        height: 38px;
        margin-top: 0;
        overflow: auto;
    }
}

@media only screen and (max-width: 720px) {
    .printToMousePad {
        float: none;
        margin-bottom: 22px;
        margin-left: 2px;
        padding-left: 20px;
        padding-right: 20px;
    }
}

.loginFirst {
    font-weight: bold;
}

.loginFirst:hover {
    text-decoration: underline;
}

.keyPairRow {
    clear: both;
    padding: 8px;
}

.keyLeft {
    float: left;
    width: 200px;
    border-bottom: 1px solid #333;
}

.valueRight {
    float: left;
    width: 100px;
    border-bottom: 1px solid #333;
    text-align: right;
}